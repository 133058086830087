import React, { FC, useContext } from 'react'
import { ThemeContext } from 'styled-components'

export const SocialMediaIcon: FC = () => {
  const {
    colors: { blue, red, orange },
  } = useContext(ThemeContext)

  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 111 73"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="32.4088"
        y="10.9949"
        width="36.9211"
        height="61.137"
        rx="3.28627"
        fill={blue}
        stroke={blue}
        stroke-width="1.31451"
      />
      <circle
        cx="69.5308"
        cy="11.8145"
        r="8.20366"
        fill={blue}
        stroke={blue}
        stroke-width="1.31451"
      />
      <rect
        x="29.3347"
        y="8.04135"
        width="36.9211"
        height="61.137"
        rx="3.28627"
        fill={red}
        stroke={orange}
        stroke-width="1.31451"
      />
      <path
        d="M47.7951 16.3057L50.5595 23.9716L58.7044 24.2318L52.2679 29.2297L54.5374 37.0564L47.7951 32.4794L41.0528 37.0564L43.3223 29.2297L36.8859 24.2318L45.0308 23.9716L47.7951 16.3057Z"
        fill={orange}
      />
      <rect
        x="37.5989"
        y="44.3452"
        width="20.3923"
        height="2.54904"
        fill={orange}
      />
      <rect
        x="37.5989"
        y="49.4433"
        width="20.3923"
        height="2.54904"
        fill={orange}
      />
      <rect
        x="37.5989"
        y="54.5413"
        width="20.3923"
        height="2.54904"
        fill={orange}
      />
      <path
        d="M29.5364 64.2416H65.7184V66.7003C65.7184 67.7892 64.8356 68.672 63.7467 68.672H31.5081C30.4192 68.672 29.5364 67.7892 29.5364 66.7003V64.2416Z"
        fill={orange}
      />
      <circle
        cx="66.4569"
        cy="8.86091"
        r="8.20366"
        fill={red}
        stroke={orange}
        stroke-width="1.31451"
      />
      <path
        d="M66.6044 13.4095C65.8404 13.4095 65.1984 13.2914 64.6786 13.0551C64.1666 12.8188 63.7846 12.5274 63.5326 12.1808C63.2884 11.8264 63.1585 11.4719 63.1427 11.1175C63.1427 11.0466 63.1663 10.9875 63.2136 10.9403C63.2687 10.893 63.3317 10.8694 63.4026 10.8694H64.9503C65.037 10.8694 65.1079 10.8851 65.163 10.9166C65.2181 10.9481 65.2693 11.0072 65.3166 11.0939C65.4741 11.5034 65.9034 11.7082 66.6044 11.7082C67.0061 11.7082 67.3172 11.6176 67.5377 11.4365C67.7661 11.2474 67.8803 10.9915 67.8803 10.6685C67.8803 10.0384 67.4708 9.72337 66.6516 9.72337H65.3402C65.2614 9.72337 65.1906 9.6958 65.1275 9.64067C65.0724 9.57766 65.0448 9.50677 65.0448 9.42801V8.76639C65.0448 8.59311 65.1079 8.45921 65.2339 8.3647L67.1715 6.72247H63.887C63.8083 6.72247 63.7374 6.69491 63.6744 6.63977C63.6192 6.57676 63.5917 6.50587 63.5917 6.42711V5.31654C63.5917 5.2299 63.6192 5.15902 63.6744 5.10388C63.7374 5.04875 63.8083 5.02118 63.887 5.02118H69.3217C69.4083 5.02118 69.4792 5.04875 69.5344 5.10388C69.5974 5.15902 69.6289 5.2299 69.6289 5.31654V6.32078C69.6289 6.47043 69.5659 6.60039 69.4399 6.71066L67.644 8.37651L67.7267 8.40014C68.4514 8.49466 69.0224 8.73882 69.4399 9.13264C69.8652 9.51858 70.0778 10.0739 70.0778 10.7985C70.0778 11.3262 69.9282 11.787 69.6289 12.1808C69.3296 12.5746 68.9161 12.8779 68.3884 13.0905C67.8606 13.3032 67.266 13.4095 66.6044 13.4095Z"
        fill="white"
      />
      <path
        d="M65.6372 38.6199V38.6198C65.6368 36.3602 66.5538 34.1941 68.1841 32.609C69.8145 31.0237 72.0215 30.1531 74.3088 30.1951L74.3166 30.1952L74.3243 30.1952C77.0548 30.1808 79.6586 31.3276 81.4753 33.3418L81.9634 33.8829L82.4514 33.3418C84.2682 31.3276 86.872 30.1808 89.6025 30.1952L89.6102 30.1952L89.618 30.1951C91.9053 30.1531 94.1123 31.0237 95.7427 32.609C97.373 34.1941 98.2899 36.3602 98.2895 38.6198V38.6199C98.2895 42.8542 95.7403 46.645 92.295 50.112C90.5812 51.8366 88.672 53.4554 86.7958 54.9871C86.2568 55.4271 85.7191 55.8612 85.1894 56.2886C84.0546 57.2045 82.9572 58.0902 81.9656 58.94C80.932 58.0458 79.7831 57.1156 78.5961 56.1544C78.1143 55.7643 77.6263 55.3691 77.1371 54.9692C75.2609 53.4354 73.3511 51.8185 71.6363 50.097C68.1891 46.6362 65.6372 42.8569 65.6372 38.6199Z"
        fill="#182E74"
        stroke="#182E74"
        stroke-width="1.31451"
      />
      <path
        d="M62.6834 35.6662V35.6661C62.683 33.4066 63.6 31.2404 65.2302 29.6553C66.8607 28.0701 69.0677 27.1995 71.3549 27.2414L71.3627 27.2415L71.3705 27.2415C74.1009 27.2271 76.7048 28.3739 78.5215 30.3882L79.0095 30.9293L79.4976 30.3882C81.3143 28.3739 83.9181 27.2271 86.6486 27.2415L86.6564 27.2415L86.6641 27.2414C88.9514 27.1995 91.1584 28.0701 92.7889 29.6553C94.4191 31.2404 95.3361 33.4066 95.3357 35.6661V35.6662C95.3357 39.9005 92.7864 43.6913 89.3412 47.1583C87.6274 48.8829 85.7181 50.5018 83.8419 52.0335C83.303 52.4735 82.7652 52.9075 82.2356 53.3349C81.1008 54.2508 80.0033 55.1365 79.0117 55.9863C77.9781 55.0921 76.8293 54.1619 75.6423 53.2008C75.1605 52.8107 74.6724 52.4155 74.1832 52.0155C72.3071 50.4818 70.3973 48.8649 68.6824 47.1433C65.2353 43.6825 62.6834 39.9032 62.6834 35.6662Z"
        fill={red}
        stroke="#182E74"
        stroke-width="1.31451"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M14.1528 9.59928C14.1528 13.3554 17.1832 16.4004 20.9215 16.4004C24.6579 16.3959 27.6858 13.3536 27.6903 9.59928C27.6903 5.84312 24.6598 2.79814 20.9215 2.79814C17.1832 2.79814 14.1528 5.84312 14.1528 9.59928Z"
        fill={blue}
      />
      <path
        d="M12.3067 31.0131H9.59924V29.6529C9.60521 24.3967 13.8444 20.1373 19.0755 20.1313H24.4905C29.7216 20.1373 33.9608 24.3967 33.9667 29.6529V31.0131H31.2592V29.6529C31.2548 25.8986 28.2269 22.8562 24.4905 22.8517H19.0755C15.3391 22.8562 12.3112 25.8986 12.3067 29.6529V31.0131Z"
        fill={blue}
      />
      <path
        d="M31.2592 31.0131V29.6529C31.2548 25.8986 28.2269 22.8562 24.4905 22.8517H19.0755C15.3391 22.8562 12.3112 25.8986 12.3067 29.6529V31.0131H31.2592Z"
        fill={blue}
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M12.0606 7.59783C12.0606 11.354 15.0911 14.399 18.8294 14.399C22.5658 14.3945 25.5937 11.3521 25.5981 7.59783C25.5981 3.84166 22.5677 0.796692 18.8294 0.796692C15.0911 0.796692 12.0606 3.84166 12.0606 7.59783Z"
        fill="white"
      />
      <path
        d="M9.35313 28.7979H6.64563V27.4377C6.6516 22.1815 10.8908 17.9221 16.1219 17.9161H21.5369C26.768 17.9221 31.0072 22.1815 31.0131 27.4377V28.7979H28.3056V27.4377C28.3012 23.6834 25.2733 20.6411 21.5369 20.6366H16.1219C12.3855 20.6411 9.35761 23.6834 9.35313 27.4377V28.7979Z"
        fill="white"
      />
      <path
        d="M28.3056 28.7979V27.4377C28.3012 23.6834 25.2733 20.6411 21.5369 20.6366H16.1219C12.3855 20.6411 9.35761 23.6834 9.35313 27.4377V28.7979H28.3056Z"
        fill="white"
      />
      <path
        d="M2.95361 42.0892H34.7052V61.2879H22.1522V66.8259L15.1374 61.2879H2.95361V42.0892Z"
        fill="#182E74"
      />
      <path
        d="M0 39.874H31.7516V59.0727H19.1986V64.6107L12.1837 59.0727H0V39.874Z"
        fill={orange}
      />
      <circle cx="15.5066" cy="49.4734" r="2.21523" fill={blue} />
      <circle cx="22.8906" cy="49.4734" r="2.21523" fill={blue} />
      <circle cx="8.12245" cy="49.4734" r="2.21523" fill={blue} />
      <rect
        x="84.2607"
        y="4.97418"
        width="25.84"
        height="16.8522"
        fill={orange}
      />
      <rect
        x="87.6311"
        y="1.60376"
        width="5.61739"
        height="3.37043"
        fill={orange}
      />
      <rect
        x="88.7546"
        y="2.72723"
        width="3.37043"
        height="2.24695"
        fill={red}
      />
      <circle cx="97.1807" cy="13.4003" r="6.17913" fill={blue} />
      <circle cx="106.168" cy="8.90635" r="1.68522" fill={red} />
    </svg>
  )
}
